import axios from "axios";
import { isUnauthorized, logoutOn401 } from "./auth";
import { adminApiSafeSearchList } from "./adminApi";

const SERVER_URL = process.env.GATSBY_API_URL || "/api/v2";
const PROFILE_ENDPOINT = `${SERVER_URL}/user`;
const DOMAIN_ENDPOINT = `${SERVER_URL}/domain`;
const CHILD_ENDPOINT = `${SERVER_URL}/child`;
const CHILD_USER_ENDPOINT = `${SERVER_URL}/child/user`;
const RESET_PASSWORD_ENDPOINT = `${SERVER_URL}/resetpassword`;
const DYNDNS_ENDPOINT = `${SERVER_URL}/dyndns`;
const SAFESEARCH_ENDPOINT = `${SERVER_URL}/safesearch`;
const PORTAL_ENDPOINT = `${SERVER_URL}/portal`;
const LOGFILE_ENDPOINT = `${SERVER_URL}/logfile`;
const USERQUOTA_ENDPOINT = `${SERVER_URL}/userquotaperday`;

// const invalidCredentialsHandler = () => {
//   console.log('invalid credentials');
// };

axios.defaults.withCredentials = true;

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // do nothing in case it was a success
        return response;
    },
    function (error) {
        const handled = logoutOn401(error.response.status);
        if (!handled) {
            return Promise.reject(error);
        }
    },
);

/**
 * Get the user profile
 *
 * @param {string} username - 'me' or the username of the profile to fetch
 * @returns {Promise<API.User>}
 */
const apiFetchProfile = async (username) => {
    try {
        const { status, data } = await axios.get(
            `${PROFILE_ENDPOINT}/${username}`,
        );

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 *
 * @param {object} updatedProfile
 * @returns {Promise<API.User>}
 */
const apiUpdateProfile = async (updatedProfile) => {
    try {
        const { status, data } = await axios.patch(
            `${PROFILE_ENDPOINT}/${updatedProfile.id}`,
            {
                ...updatedProfile,
                name: updatedProfile.name
                    ? updatedProfile.name
                    : updatedProfile.username,
            },
        );

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 * @param {"whitelist" | "blacklist" | undefined} list
 * @returns {Promise<API.DomainObject[]>}
 */
const apiFetchDomains = async (list = undefined) => {
    try {
        const { status, data } = await axios.get(DOMAIN_ENDPOINT, {
            params: list ? { [list]: true } : {},
        });

        if (status === 200) {
            return data;
        }
    } catch (error) {
        if (isUnauthorized(error)) {
            console.log("nope");
        }

        return undefined;
    }
};

/**
 *
 * @param {Pick<API.DomainObject, "domain" | "block">} domainObj
 * @returns {Promise<API.DomainObject>}
 */
const apiAddDomain = async (domainObj) => {
    try {
        const { status, data } = await axios.post(DOMAIN_ENDPOINT, domainObj);

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 *
 * @param {string} id
 * @returns {Promise<{ delete: boolean; }>}
 */
const apiRemoveDomain = async (id) => {
    try {
        const { status, data } = await axios.delete(`${DOMAIN_ENDPOINT}/${id}`);

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 *
 * @param {API.DomainObject} updatedDomain
 * @returns {Promise<API.DomainObject>}
 */
const apiUpdateDomain = async (updatedDomain) => {
    try {
        const { status, data } = await axios.patch(
            `${DOMAIN_ENDPOINT}/${updatedDomain.id}`,
            updatedDomain,
        );

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 *
 * @param {string | undefined} userId
 * @returns {Promise<API.Child[]>}
 */
const apiFetchChildren = async (userId = undefined) => {
    try {
        const { status, data } = await axios.get(
            `${!userId ? CHILD_ENDPOINT : CHILD_USER_ENDPOINT + "/" + userId}`,
        );

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 *
 * @param {string} childId
 * @returns {Promise<API.Child>}
 */
const apiFetchChild = async (childId) => {
    try {
        const { status, data } = await axios.get(
            `${CHILD_ENDPOINT}/${childId}`,
        );

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 *
 * @param {object} childObj
 * @returns {Promise<API.Child>}
 */
const apiAddChild = async (childObj) => {
    try {
        const { status, data } = await axios.post(CHILD_ENDPOINT, childObj);

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 *
 * @param {string} id
 * @returns {Promise<{ delete: boolean; }>}
 */
const apiRemoveChild = async (id) => {
    try {
        const { status, data } = await axios.delete(`${CHILD_ENDPOINT}/${id}`);

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 *
 * @param {object} updatedChild
 * @returns {Promise<API.Child>}
 */
const apiUpdateChild = async (updatedChild) => {
    try {
        const { status, data } = await axios.patch(
            `${CHILD_ENDPOINT}/${updatedChild.id}`,
            updatedChild,
        );

        if (status === 200) {
            return data;
        }
    } catch (error) {
        console.error(error);
    }

    return undefined;
};

/**
 *
 * @param {{username: string, email: string , uri: string}} requestData
 * @returns Promise<{message: string}>
 */
const apiResetPasswordRequest = async (requestData) => {
    try {
        const { status, data } = await axios.post(
            `${RESET_PASSWORD_ENDPOINT}`,
            requestData,
        );

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }

    return undefined;
};

/**
 *
 * @param {{password: string, token: string}} requestData
 */
const apiResetPasswordConfirm = async (requestData) => {
    try {
        const { token, ...rest } = requestData;

        const { status, data } = await axios.patch(
            `${RESET_PASSWORD_ENDPOINT}/${requestData.token}`,
            rest,
        );

        if (status === 200) {
            return data;
        }
    } catch (error) {
        console.error(error);
    }

    return undefined;
};

/**
 * @returns {Promise<API.DynDNS>}
 */
const apiGetDynDns = async () => {
    try {
        const { status, data } = await axios.get(`${DYNDNS_ENDPOINT}`);

        if (status === 200) {
            return data;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * @param {string} password
 * @param {string} profileId
 */
const deleteProfile = async (password, profileId) => {
    try {
        const { status } = await axios({
            method: "delete",
            responseType: "json",
            data: {
                password: password,
            },
            url: `${PROFILE_ENDPOINT}/${profileId}`,
        });

        return status;
    } catch (error) {
        return error.response.status;
    }
};

/**
 * @param {string} domain
 */
const apiClearDomainCache = async (domain) => {
    try {
        const { status } = await axios.post(`${SERVER_URL}/clear-cache`, {
            domain,
        });

        return status;
    } catch (error) {
        return error.response.status;
    }
};

export {
    apiFetchProfile,
    apiUpdateProfile,
    apiFetchDomains,
    apiAddDomain,
    apiRemoveDomain,
    apiUpdateDomain,
    apiFetchChild,
    apiFetchChildren,
    apiAddChild,
    apiRemoveChild,
    apiUpdateChild,
    apiResetPasswordRequest,
    apiResetPasswordConfirm,
    apiGetDynDns,
    deleteProfile,
    apiClearDomainCache,
    SERVER_URL,
    SAFESEARCH_ENDPOINT,
    PORTAL_ENDPOINT,
    LOGFILE_ENDPOINT,
    adminApiSafeSearchList as apiSafeSearchList,
    PROFILE_ENDPOINT as USER_ENDPOINT,
    USERQUOTA_ENDPOINT,
};
