import axios from "axios";
import { SERVER_URL } from "./api";

/**
 * Login
 *
 * @param {{ formRef: import('react').RefObject<HTMLFormElement> }} state
 */
const login = async ({ formRef }) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/login`;

    const formData = new FormData(formRef.current);
    // @ts-ignore
    const { username, password } = Object.fromEntries(formData.entries());

    const res = await fetch(LOGIN_ENDPOINT, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ username, password, redirect_to: `/` }),
        credentials: "include",
    });

    const status = res.status;
    // const data = await res.json();

    if (status === 200) {
        window.location.reload();
    }
};

/**
 * Login
 *
 * @param {string} username
 */
export const impersonate = async (username) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/login`;

    await fetch(LOGIN_ENDPOINT, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ username }),
        credentials: "include",
        redirect: "manual",
    });

    window.location.href = "/";
};

/**
 * Register a new user
 *
 * @param {{ username: string, name: string, email: string, password: string, type: string }} param0
 */
const register = async ({ username, name, email, password, type }) => {
    const SIGNUP_ENDPOINT = `${SERVER_URL}/user`;

    try {
        let { status } = await axios({
            method: "post",
            responseType: "json",
            url: SIGNUP_ENDPOINT,
            data: {
                username: username,
                name: name,
                email: email,
                password: password,
                type: type,
            },
        });

        return status;
    } catch (error) {
        return error.response.status;
    }
};

/**
 * Remove the localStorage data
 *
 * @param {function} callback
 */
const logout = async (callback) => {
    await fetch(`${SERVER_URL}/logout`, {
        method: "POST",
        credentials: "include",
    });

    callback();
};

function logoutOn401(statusCode) {
    if (statusCode === 401) {
        logout(() => {
            window.location.reload();
        });
        return true;
    }

    return false;
}

/**
 *
 * @param {object} error
 * @returns {boolean}
 */
const isUnauthorized = (error) => {
    const msg = error.response.data.message;
    return msg === "unauthorized";
};

export { login, register, logout, isUnauthorized, logoutOn401 };
