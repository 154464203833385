import axios from "axios";

import {
    LOGFILE_ENDPOINT,
    PORTAL_ENDPOINT,
    SAFESEARCH_ENDPOINT,
    USER_ENDPOINT,
    USERQUOTA_ENDPOINT,
} from "./api";

/**
 * @typedef {{ fieldname: string, direction: 'ASC' | 'DESC' }} FieldSorting
 */

/**
 * Get the user profile
 *
 * @param {number} itemsPerPage
 * @param {number} currentPage
 * @param {'ASC' | 'DESC'} sorting
 * @param {string} search
 * @param {string} type
 * @param {string} quota
 */
const adminApiUserlist = async (
    itemsPerPage,
    currentPage,
    sorting,
    search,
    type,
    quota,
) => {
    const searchParams = new URLSearchParams();
    if (sorting.length > 0) {
        searchParams.set("sorting", JSON.stringify(sorting));
    }
    searchParams.set("limit", `${itemsPerPage}`);
    searchParams.set(
        "offset",
        Math.max(0, (currentPage - 1) * itemsPerPage).toString(),
    );
    searchParams.set("search", search);
    searchParams.set("type", type);
    searchParams.set("quotaStateType", quota);

    try {
        let { status, data } = await axios({
            method: "get",
            responseType: "json",

            url: `${USER_ENDPOINT}?${searchParams.toString()}`,
        });

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 * @param {API.User} updatedUser
 */
const adminApiUserUpdate = async (updatedUser) => {
    try {
        let { status, data } = await axios({
            method: "patch",
            responseType: "json",
            data: updatedUser,
            url: `${USER_ENDPOINT}/${updatedUser.id}`,
        });

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 * @param {string} id
 * @param {string} comment
 */
const adminApiUserSetComment = async (id, comment) => {
    try {
        let { status, data } = await axios({
            method: "post",
            responseType: "json",
            data: { comment },
            url: `${USER_ENDPOINT}/${id}/comment`,
        });

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

/**
 * @param {string} id
 * @param {number} max
 */
const adminApiUserSetMaxQuota = async (id, max) => {
    try {
        let { status, data } = await axios({
            method: "post",
            responseType: "json",
            data: { max },
            url: `${USER_ENDPOINT}/${id}/quota`,
        });

        if (status === 200) {
            return data;
        }
    } catch (error) {
        return undefined;
    }
};

const adminApiSafeSearchList = async () => {
    try {
        let { status, data } = await axios({
            method: "get",
            responseType: "json",

            url: `${SAFESEARCH_ENDPOINT}`,
        });

        if (status === 200) {
            return data;
        }

        if (status === 401) {
        }
    } catch (error) {
        return undefined;
    }
};

const adminApiSafeSearchAdd = async (sendData) => {
    try {
        let { status, data } = await axios({
            method: "post",
            responseType: "json",

            data: sendData,
            url: `${SAFESEARCH_ENDPOINT}`,
        });

        if (status === 201) {
            return data;
        }

        if (status === 401) {
        }
    } catch (error) {
        return undefined;
    }
};

const adminApiSafeSearchUpdate = async (sendData) => {
    try {
        const { id, ...rest } = sendData;

        let { status, data } = await axios({
            method: "patch",
            responseType: "json",
            data: rest,
            url: `${SAFESEARCH_ENDPOINT}/${id}`,
        });

        if (status === 200) {
            return data;
        }

        if (status === 401) {
        }
    } catch (error) {
        return undefined;
    }
};

const adminApiSafeSearchDelete = async (id) => {
    try {
        let { status, data } = await axios({
            method: "delete",
            responseType: "json",
            url: `${SAFESEARCH_ENDPOINT}/${id}`,
        });

        if (status === 200) {
            return data;
        }

        if (status === 401) {
        }
    } catch (error) {
        return undefined;
    }
};

/**
 * @returns {Promise<API.PortalObject[]>}
 */
const adminApiPortalList = async () => {
    try {
        let { status, data } = await axios({
            method: "get",
            responseType: "json",
            url: `${PORTAL_ENDPOINT}`,
        });

        if (status === 200) {
            return data;
        }

        if (status === 401) {
        }
    } catch (error) {
        return undefined;
    }
};

const adminApiPortalAdd = async (sendData) => {
    try {
        let { status, data } = await axios({
            method: "post",
            responseType: "json",
            data: sendData,
            url: `${PORTAL_ENDPOINT}`,
        });

        if (status === 201) {
            return data;
        }

        if (status === 401) {
        }
    } catch (error) {
        return undefined;
    }
};

const adminApiPortalUpdate = async (sendData) => {
    try {
        const { id, ...rest } = sendData;

        let { status, data } = await axios({
            method: "patch",
            responseType: "json",
            data: rest,
            url: `${PORTAL_ENDPOINT}/${id}`,
        });

        if (status === 200) {
            return data;
        }

        if (status === 401) {
        }
    } catch (error) {
        return undefined;
    }
};

const adminApiPortalDelete = async (id) => {
    try {
        let { status, data } = await axios({
            method: "delete",
            responseType: "json",
            url: `${PORTAL_ENDPOINT}/${id}`,
        });

        if (status === 200) {
            return data;
        }

        if (status === 401) {
        }
    } catch (error) {
        return undefined;
    }
};

const adminApiLogfile = async () => {
    try {
        let { status, data } = await axios({
            method: "get",
            responseType: "json",
            url: `${LOGFILE_ENDPOINT}`,
        });

        if (status === 200) {
            return data;
        }

        if (status === 401) {
        }
    } catch (error) {
        return undefined;
    }
};

const adminApiUserQuota = async (sendData) => {
    try {
        let { status, data } = await axios({
            method: "post",
            responseType: "json",
            data: sendData,
            url: `${USERQUOTA_ENDPOINT}`,
        });

        return data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Handle 401 error
        } else if (error.response && error.response.status === 404) {
            throw new Error("404");
        } else {
            throw error;
        }
    }
};

export {
    adminApiLogfile,
    adminApiPortalAdd,
    adminApiPortalDelete,
    adminApiPortalList,
    adminApiPortalUpdate,
    adminApiSafeSearchAdd,
    adminApiSafeSearchDelete,
    adminApiSafeSearchList,
    adminApiSafeSearchUpdate,
    adminApiUserlist,
    adminApiUserQuota,
    adminApiUserSetComment,
    adminApiUserSetMaxQuota,
    adminApiUserUpdate,
};
